$text1: "Roboto", sans-serif;
$text2: "Open Sans", sans-serif;

$color1: #fef2f2;
$color2: #286f6c;
$color2_dark: #174340;
$color3: #f26440;
$color3_light: rgb(249, 141, 141);
$color4: #fff;
$color5: #292b46;
$color6: #161616;
$color6_1: #16161698;
$color6_2: #16161622;

$p: 13.33vmax;
$p_md: 7vmax;
$p_base: 5vmax;
$p_base_inverse: 15vmax 10vmax;
$p_sm: 2vmax;
$p_sm_inverse: 13vmax 0;
$p_zero: 0;
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;1,100;1,400;1,500;1,900&display=swap");

@import "./static.scss";
@import "./header.scss";
@import "./contact.scss";
@import "./mediaQuery.scss";

* {
  margin: 0;
  padding: 0;
  font-family: $text2;
  box-sizing: border-box;
  scroll-behavior: smooth;
}

a {
  text-decoration: none;
}

body {
  background-color: $color1;
  overflow-x: hidden;
}

.disableBtn {
  background-color: rgb(237, 237, 237);
  cursor: not-allowed;
}
@import "./static.scss";
#home {
  width: 100%;
  height: auto;
  padding: 30px 0;
  display: flex;

  > section {
    width: 100%;

    &:first-of-type {
      padding-left: 8rem;

      > div {
        padding-top: 4rem;
        height: 100%;

        > h1 {
          font: 900 4rem $text1;
        }

        .typewriterPara {
          margin: 20px 0;
          height: 20px;
          letter-spacing: 0.4rem;
          font-size: 1.5rem;
        }
        > div {
          display: flex;
          margin: 4rem 0;
          width: 100%;
          gap: 2rem;
          align-items: center;

          .links {
            width: 40px;
            height: 40px;
            color: $color6;

            &:hover {
              color: $color3;
            }
          }

          .download {
            > button {
              width: 120%;
              padding: 1rem;
              cursor: pointer;
              background-color: $color3;
              color: $color4;
              font: 600 1rem $text1;
              text-transform: uppercase;
              border: none;
              letter-spacing: 0.15rem;

              &:hover {
                opacity: 0.9;
              }
            }
          }
        }
      }
    }

    &:last-of-type {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      overflow-x: hidden;
      padding-right: 6rem;

      &::after {
        content: "";
        width: 40vmax;
        height: 40vmax;
        background-color: $color3;
        position: absolute;
        z-index: -1;
        border-radius: 50%;
      }

      > img {
        width: 40vmax;
        object-fit: contain;
        height: 40vmax;
      }
    }
  }
}

.nav {
  width: 100%;
  height: 100px;
  background-color: $color1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 8rem;
  position: sticky;
  top: 0;
  z-index: 100;
  border-bottom: 1px solid $color6_2;

  > h2 > a {
    font-size: 2rem;
    color: $color6;
    &:hover {
      cursor: pointer;
      color: $color3;
    }
  }

  .nav-content {
    > ul {
      display: flex;
      flex-direction: row;
      gap: 3rem;

      > li {
        list-style-type: none;
        > a {
          color: $color6;
          font-size: 1.05rem;
          font-weight: 500;
          transition: all 0.3s;
          letter-spacing: 1px;

          &:hover {
            color: $color3;
          }
        }
      }
    }
  }

  // > a {

  //   > button {
  //     border: 1px solid $color6_1;
  //     padding: 0.5rem 2rem;
  //     background-color: $color1;
  //     cursor: pointer;
  //     color: $color6;
  //     letter-spacing: 1px;
  //     font-weight: 500;
  //     transition: all 0.3s;
  //     &:hover {
  //       color: $color1;
  //       background-color: $color6;
  //     }
  //   }
  // }

  > button {
    display: none;
    border: none;
    background-color: transparent;
    color: $color5;
    font-size: 1.8rem;
    &:hover {
      cursor: pointer;
    }
  }
}

#contact {
  width: 100%;
  height: auto;
  padding: 0px 0;
  display: grid;
  grid-template-columns: 1fr 1fr;

  > section {
    background-color: $color1;
    width: 100%;
    height: 100%;

    > form {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: 100%;

      > h2 {
        font: 100 2.7rem $text2;
        letter-spacing: 5px;
        text-transform: uppercase;
        margin: 2rem;
        color: rgb(242, 100, 63);
        font-weight: 400;
      }

      > input,
      > textarea,
      > button {
        padding: 0.8rem;
        width: 60%;
        outline: none;
        font: 400 0.9rem $text1;
        margin: 1rem;
        border: none;
      }

      > textarea {
        height: 150px;
        resize: vertical;
      }

      > button {
        cursor: pointer;
        background-color: $color3;
        color: $color4;
        font: 600 1rem $text1;
        text-transform: uppercase;
        margin: 3rem;

        &:hover {
          opacity: 0.9;
        }
      }
    }
  }

  > aside {
    width: 100%;
    height: 100%;
    // background-color: $color5;
    display: grid;
    place-items: center;

    > img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      filter: drop-shadow(0 0 5px rgba(0, 0, 0, 0.6));
      animation: updown 0.7s linear infinite alternate;
      margin-top: 1.5rem;
    }
  }
}

@keyframes updown {
  to {
    transform: translateY(-10px);
  }
}

@media screen and (max-width: 1367px) {
  nav {
    padding: 0 $p_md;
  }
  #home {
    height: 100vh;

    > section {
      &:first-of-type {
        padding-left: $p_md;
        > div aside {
          width: 85%;
        }
      }
    }
  }
}

@media screen and (max-width: 1350px) {
  .about {
    .about-top {
      .logos {
        > ul {
          gap: 1rem;
        }
      }
    }
    .all-about {
      padding: 1.5rem 4rem;
      > div {
        gap: 2rem;
        > img {
          width: 450px;
        }
        > p {
          line-height: 1.4;
        }
      }
    }
  }

  .projects {
    > div {
      width: 80%;
      > img {
        width: 400px;
      }
    }
  }
}

@media screen and (max-width: 1200px) {
  .nav {
    .nav-content {
      position: fixed;
      right: 0;
      top: 100px;
      background-color: rgb(254, 241, 242);
      transition: all 0.5s ease-in-out;
      border-radius: 40px;
      width: 100%;
      padding: 3rem;
      > ul {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 3rem;
      }
    }
    .display {
      display: inline;
    }
    .hide {
      display: none;
    }

    > a {
      display: none;
    }
    > button {
      display: flex;
      align-items: center;
    }
  }
  #home {
    height: auto;
    flex-direction: column-reverse;

    > section {
      &:first-of-type {
        > div {
          padding-top: 2rem !important;
          .typewriterPara {
            height: 90px !important;
          }
          > div {
            margin: 1rem 0 !important;
            .download {
              > button {
                margin-top: 3rem;
              }
            }
          }
        }
      }
      &:last-of-type {
        padding-right: 0 !important;
      }
    }

    .intro {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0 !important;

      > div {
        width: 62%;
      }
    }
  }

  .about {
    .about-top {
      width: 95%;
    }
    .all-about {
      display: flex;
      flex-direction: column-reverse;
      > div {
        width: 100%;
        > img {
          display: none;
        }
        > h2 {
          font-size: 2.4rem;
        }
        > h3 {
          font-size: 1.7rem;
          line-height: 1.4;
        }
        > p {
          font-size: 1.2rem;
          line-height: 1.5;
        }
      }
    }
  }

  #contact {
    grid-template-columns: 1fr;
    > section {
      width: 100%;
    }
    > aside {
      display: none;
    }
  }
}

@media screen and (max-width: 1000px) {
  .nav {
    padding: 0 2rem;
  }

  .about {
    .about-top {
      flex-direction: column;
      padding-top: 1rem;
      padding-bottom: 2rem;
      > p {
        border: none;
        padding-right: 0;
        margin: 2rem 0 1.5rem 0;
      }
      .logos {
        > ul {
          width: 100%;
          display: flex;
          justify-content: center;
          flex-wrap: wrap;
          gap: 2rem;
        }
      }
    }

    .all-about {
      padding: 1rem;
    }
  }

  .projects {
    padding: 0;
    > div {
      width: 100%;
      flex-direction: column;
      gap: 1.5rem;

      > img {
        width: 95%;
      }
      > div {
        padding-top: 0;
      }
    }
    .mini-projects {
      gap: 3rem;
      > div {
        gap: 1.5rem;
        flex-direction: column;

        > img {
          width: 90%;
          align-items: center;
        }
        > div {
          padding-top: 0;
          padding-right: 0;
        }
      }
    }
  }

  #contact {
    > section {
      > form {
        > input,
        > textarea,
        > button {
          width: 90%;
        }
      }
    }
  }
}
